import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHeroIcons } from './models/hero-icons.model';

@Component({
  selector: 'lib-hero-icons',
  templateUrl: './hero-icons.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class HeroIconsComponent {
  @Input() iconName: IHeroIcons['iconName'] | undefined = null;
  @Input() size!: number;
  @Input() strokeWidth = 1.5;
  @Input() currentColor: string = 'text-white'
}
